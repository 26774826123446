import React from "react"
import { StyleSheet, Text } from "react-native"
import { Colors, Spacing, Typography } from "../styles"

export default function CardTitle(props) {
  const { children, style, ...rest } = props
  return (
    <Text style={[styles.cardTitle, style]} {...rest}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  cardTitle: {
    color: Colors.black,
    fontFamily: Typography.headerFontFamily,
    fontSize: Typography.baseFontSize,
    lineHeight: Typography.baseFontSize + Spacing.tiny,
    textTransform: "uppercase",
    marginBottom: Spacing.smallest,
  },
})
