import React from "react"
import { StyleSheet, View } from "react-native"
import { Spacing } from "../styles"

export default function CardHeader(props) {
  const { children, style, ...rest } = props
  return (
    <View style={[styles.cardHeader, style]} {...rest}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  cardHeader: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    padding: Spacing.base,
  },
})
