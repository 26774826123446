import React from "react"
import { Typography } from "../styles"
import Heading from "./Heading"

export default function SmallHeading({ children, style }) {
  return (
    <Heading
      style={[
        {
          fontSize: Typography.smallFontSize,
        },
        style,
      ]}
    >
      {children}
    </Heading>
  )
}
