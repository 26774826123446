import React, { useEffect, useMemo, useReducer } from "react"
import { Platform } from "react-native"
import * as Device from "expo-device"
import Constants from "expo-constants"
import { AppLoading } from "expo"
import { useFonts } from "expo-font"
import { StatusBar } from "expo-status-bar"
import {
  TitilliumWeb_300Light,
  TitilliumWeb_700Bold,
} from "@expo-google-fonts/titillium-web"
import {
  Lato_300Light,
  Lato_400Regular,
  Lato_700Bold,
} from "@expo-google-fonts/lato"
import AsyncStorage from "@react-native-community/async-storage"
import axios from "axios"
import * as Sentry from "sentry-expo"

import captureException from "./src/utils/captureException"

import AuthContext from "./src/contexts/auth"
import RootNavigator from "./src/navigation/RootNavigator"

Sentry.init({
  dsn:
    "https://31965eef30bb49ee9756abc79f3c0560@o142485.ingest.sentry.io/5462745",
  enableInExpoDevelopment: true,
  debug: true, // Sentry will try to print out useful debugging information if something goes wrong with sending an event. Set this to `false` in production.
})

axios.defaults.baseURL = __DEV__
  ? //? "http://192.168.86.215:4000"
    "http://localhost:4000"
  : "https://api.sparkgolf.dev"

export default function App() {
  let [fontsLoaded] = useFonts({
    TitilliumWeb_300Light,
    TitilliumWeb_700Bold,
    Lato_300Light,
    Lato_400Regular,
    Lato_700Bold,
  })

  const authReducer = (prevState, action) => {
    switch (action.type) {
      case "SET_TOKEN":
        return {
          ...prevState,
          isLoading: false,
          token: action.token,
        }
      case "REMOVE_TOKEN":
        return {
          ...prevState,
          isLoading: false,
          token: null,
        }
    }
  }

  const [authState, dispatch] = useReducer(authReducer, {
    isLoading: true,
    token: null,
  })

  const authContext = useMemo(
    () => ({
      setToken: async (token) => {
        try {
          await AsyncStorage.setItem("authToken", token)
          if (token) {
            axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
          } else {
            delete axios.defaults.headers.common["Authorization"]
          }
        } catch (e) {
          captureException(e)
        }
        dispatch({ type: "SET_TOKEN", token })
      },
      removeToken: async () => {
        try {
          AsyncStorage.removeItem("authToken")
          delete axios.defaults.headers.common["Authorization"]
        } catch (e) {
          captureException(e)
        }
        dispatch({ type: "REMOVE_TOKEN" })
      },
      token: authState.token,
    }),
    [authState, dispatch]
  )

  useEffect(() => {
    async function getToken() {
      let token = null
      try {
        token = await AsyncStorage.getItem("authToken")
        if (token) {
          axios.defaults.headers.common["Authorization"] = `Bearer ${token}`
        }
        axios.defaults.headers.common["X-Device-Platform"] = Platform.OS
        axios.defaults.headers.common["X-Device-OS-Name"] = Device.osName
        axios.defaults.headers.common["X-Device-OS-Version"] = Device.osVersion
        axios.defaults.headers.common["X-Device-Brand"] = Device.brand
        axios.defaults.headers.common["X-Device-Model"] = Device.modelName
        axios.defaults.headers.common["X-Device-ID"] = Constants.installationId
        axios.defaults.headers.common["X-App-Version"] =
          Constants.nativeAppVersion
        axios.defaults.headers.common["X-App-Build"] =
          Constants.nativeBuildVersion
      } catch (e) {
        captureException(e)
      }
      dispatch({ type: "SET_TOKEN", token })
    }
    getToken()
  }, [])

  if (!fontsLoaded || authState.isLoading) {
    return <AppLoading />
  } else {
    return (
      <AuthContext.Provider value={authContext}>
        <RootNavigator loggedIn={authState.token != null} />
        <StatusBar style="auto" />
      </AuthContext.Provider>
    )
  }
}
