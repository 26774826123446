import React, { useContext, useState, useRef } from "react"
import { useWindowDimensions, Platform, View } from "react-native"
import axios from "axios"
import captureException from "../utils/captureException"

import Button from "../components/Button"
import Error from "../components/Error"
import Heading from "../components/Heading"
import InlineLink from "../components/InlineLink"
import Loading from "../components/Loading"
import Screen from "../components/Screen"
import ScrollView from "../components/ScrollView"
import SmallInlineLink from "../components/SmallInlineLink"
import SmallText from "../components/SmallText"
import Text from "../components/Text"
import TextInput from "../components/TextInput"
import TopNav from "../components/TopNav"

import { Colors, Spacing } from "../styles"
import AuthContext from "../contexts/auth"

export default function SignInScreen() {
  const [emailAddress, setEmailAddress] = useState("")
  const [password, setPassword] = useState("")
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(false)

  const { setToken } = useContext(AuthContext)

  const handleSignIn = async () => {
    setLoading(true)
    try {
      const res = await axios.post("/authenticate", {
        email_address: emailAddress,
        password,
      })
      console.log(res)
      if (res.data) {
        const { status, token, message } = res.data
        if (status === "ok" && token) {
          setError(null)
          setToken(token)
        } else if (status === "error" && message) {
          setError(`Log In Failed - ${message}`)
          setLoading(false)
        } else {
          setError("Log In Failed")
          setLoading(false)
        }
      } else {
        setError("Log In Failed")
        setLoading(false)
      }
    } catch (e) {
      console.log(e)
      captureException(e)
      setLoading(false)
      setError("Server Error - Please try again later")
    }
  }

  const window = useWindowDimensions()
  const width = window.width > 432 ? 432 : window.width - Spacing.larger * 2

  const passwordRef = useRef()

  function renderMain() {
    if (loading) {
      return (
        <View style={{ flex: 1, paddingTop: 120 }}>
          <Loading />
        </View>
      )
    }

    return (
      <ScrollView contentContainerStyle={{ backgroundColor: Colors.white }}>
        <View
          style={{ width, marginTop: Spacing.extraLarge, alignSelf: "center" }}
        >
          <View style={{ marginBottom: Spacing.largest }}>
            <Heading>Sign In</Heading>
            <Text>
              Don't have an account yet?{" "}
              <InlineLink onPress={() => console.log("Sign Up pressed")}>
                Sign Up
              </InlineLink>
            </Text>
          </View>

          {error && <Error>{error}</Error>}

          <TextInput
            testID="email"
            placeholder="Email Address"
            autoCapitalize="none"
            autoCompleteType="email"
            autoCorrect={false}
            autoFocus={true}
            keyboardType="email-address"
            textContentType="emailAddress"
            onChangeText={(text) => setEmailAddress(text)}
            value={emailAddress}
            returnKeyType={"next"}
            onSubmitEditing={() => passwordRef.current.focus()}
          />

          <TextInput
            testID="password"
            ref={passwordRef}
            placeholder="Password"
            autoCapitalize="none"
            autoCompleteType="password"
            autoCorrect={false}
            autoFocus={false}
            textContentType="password"
            secureTextEntry={true}
            onChangeText={(text) => setPassword(text)}
            value={password}
            returnKeyType={"go"}
            onSubmitEditing={handleSignIn}
          />

          <View style={{ marginTop: Spacing.largest }}>
            <Button onPress={handleSignIn} testID="submit">
              Sign In
            </Button>
          </View>

          <View style={{ marginVertical: Spacing.base }}>
            <SmallText>
              Forgot password?{" "}
              <SmallInlineLink onPress={() => {}}>Reset It</SmallInlineLink>
            </SmallText>
          </View>
        </View>
      </ScrollView>
    )
  }

  return (
    <Screen>
      <TopNav menu={false} />
      {renderMain()}
    </Screen>
  )
}
