import React from "react"
import { StatusBar } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

import { Colors } from "../styles"

export default function AppScreen({ children, style }) {
  return (
    <SafeAreaView
      style={[
        {
          flex: 1,
          backgroundColor: Colors.lightGray,
        },
        style,
      ]}
    >
      {children}
      <StatusBar backgroundColor={Colors.white} />
    </SafeAreaView>
  )
}
