import React from "react"
import { StyleSheet, View } from "react-native"

import Avatar from "./Avatar"

export default function AvatarPile({ avatars }) {
  return (
    <View style={styles.pile}>
      {avatars.map((a, i) => {
        return (
          <Avatar
            key={a.id}
            imageUrl={a.imageUrl}
            placeholder={a.placeholder}
            tuck={avatars.length > 1 && i < avatars.length - 1}
          />
        )
      })}
    </View>
  )
}

const styles = StyleSheet.create({
  pile: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
})
