import React, { useContext } from "react"
import { View, StyleSheet } from "react-native"
import { DrawerContentScrollView, DrawerItem } from "@react-navigation/drawer"

import AuthContext from "../contexts/auth"

export default function DrawerContent(props) {
  const { navigation } = props
  const { removeToken } = useContext(AuthContext)

  return (
    <View style={{ flex: 1 }}>
      <DrawerContentScrollView {...props}>
        <View style={styles.drawerContent}>
          <DrawerItem
            label="My Leagues"
            labelStyle={styles.drawerItem}
            onPress={() => {
              navigation.navigate("LeagueStack")
            }}
          />
          <DrawerItem
            label="Join a League"
            labelStyle={styles.drawerItem}
            onPress={() => {
              navigation.navigate("LeaguesStack")
            }}
          />
          <DrawerItem
            label="Help"
            labelStyle={styles.drawerItem}
            onPress={() => {
              navigation.navigate("Help")
            }}
          />
          <DrawerItem
            label="Settings"
            labelStyle={styles.drawerItem}
            onPress={() => {}}
          />
          <DrawerItem
            label="Log Out"
            labelStyle={styles.drawerItem}
            onPress={removeToken}
          />
        </View>
      </DrawerContentScrollView>
    </View>
  )
}

const styles = StyleSheet.create({
  drawerContent: {
    flex: 1,
  },
  drawerItem: {
    color: "black",
    fontFamily: "TitilliumWeb_700Bold",
    textTransform: "uppercase",
  },
})
