import React from "react"
import { useWindowDimensions, ScrollView } from "react-native"
import { Colors, Spacing } from "../styles"

const defaultStyle = { flex: 1 }
const defaultContentContainerStyle = {
  backgroundColor: Colors.lightGray,
  paddingHorizontal: Spacing.base,
  paddingVertical: Spacing.largest,
}

export default function Component({
  children,
  style,
  contentContainerStyle,
  refreshControl,
}) {
  const window = useWindowDimensions()
  const desktopContentContainerStyle =
    window.width > 720 ? { width: 720, alignSelf: "center" } : {}

  return (
    <ScrollView
      refreshControl={refreshControl}
      style={{ ...defaultStyle, style }}
      contentContainerStyle={{
        ...defaultContentContainerStyle,
        ...desktopContentContainerStyle,
        ...contentContainerStyle,
      }}
    >
      {children}
    </ScrollView>
  )
}
