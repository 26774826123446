import React from "react"
import { StyleSheet, Text } from "react-native"
import { Spacing, Typography } from "../styles"

export default function Heading({ children, style }) {
  return <Text style={[styles.heading, style]}>{children}</Text>
}

export const styles = StyleSheet.create({
  heading: {
    ...Typography.headerText,
    marginBottom: Spacing.smaller,
    lineHeight: Typography.headerFontSize + Spacing.tiny,
  },
})
