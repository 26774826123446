import React from "react"
import { StatusBar } from "react-native"
import { SafeAreaView } from "react-native-safe-area-context"

import { Colors } from "../styles"

export default function Screen({ children }) {
  return (
    <SafeAreaView
      style={{
        flex: 1,
        backgroundColor: Colors.white,
      }}
    >
      {children}
      <StatusBar backgroundColor={Colors.white} translucent />
    </SafeAreaView>
  )
}
