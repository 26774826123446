import React from "react"

import Button from "./Button"

import { Buttons } from "../styles"

export default function SecondaryButton(props) {
  const { children, style, textStyle, ...rest } = props
  return (
    <Button
      style={[Buttons.secondary, style]}
      textStyle={[Buttons.secondaryText, style]}
      {...rest}
    >
      {children}
    </Button>
  )
}
