import React from "react"
import { StyleSheet, View } from "react-native"
import { Colors, Spacing } from "../styles"

export default function Card(props) {
  const { children, style, ...rest } = props
  return (
    <View style={[styles.card, style]} {...rest}>
      {children}
    </View>
  )
}

const styles = StyleSheet.create({
  card: {
    backgroundColor: Colors.white,
    borderRadius: Spacing.smaller,
    marginBottom: Spacing.larger,
    shadowColor: Colors.black,
    shadowOpacity: 0.1,
    shadowOffset: {
      width: 0,
      height: Spacing.tiny,
    },
    shadowRadius: Spacing.smallest,
  },
})
