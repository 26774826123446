import React from "react"
import { StyleSheet, Text } from "react-native"
import { Typography } from "../styles"

export default function InlineLink({ children, style, onPress }) {
  return (
    <Text onPress={onPress} style={[styles.link, style]}>
      {children}
    </Text>
  )
}

const styles = StyleSheet.create({
  link: {
    ...Typography.bodyText,
    textDecorationLine: "underline",
  },
})
