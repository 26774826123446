import React from "react"
import { Text, TouchableOpacity } from "react-native"
import { Buttons } from "../styles"

export default function Component({
  children,
  textStyle,
  style,
  onPress,
  testID,
  allowMultiplePresses,
}) {
  let pressed = false

  function handlePress() {
    if (allowMultiplePresses || !pressed) {
      pressed = true
      if (onPress) {
        onPress()
      }
    }
  }

  return (
    <TouchableOpacity
      onPress={handlePress}
      testID={testID}
      style={[Buttons.base, style]}
    >
      <Text style={[Buttons.text, textStyle]}>{children}</Text>
    </TouchableOpacity>
  )
}
