import React from "react"
import { StyleSheet } from "react-native"
import { Typography } from "../styles"
import Heading from "./Heading"

export default function SecondaryHeading({ children, style }) {
  return <Heading style={[styles.secondaryHeading, style]}>{children}</Heading>
}

export const styles = StyleSheet.create({
  secondaryHeading: {
    fontSize: Typography.largeFontSize,
  },
})
