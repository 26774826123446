import React from "react"

import Loading from "../components/Loading"
import Screen from "../components/Screen"

export default function RoundDetailsScreen() {
  return (
    <Screen>
      <Loading />
    </Screen>
  )
}
