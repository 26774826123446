import React from "react"
import { StyleSheet, View } from "react-native"

import LinkButton from "./LinkButton"

import { Spacing } from "../styles"

export default function Component({ children, width, to, testID }) {
  return (
    <View
      style={[
        styles.cardAction,
        width >= 480
          ? {
              borderTopColor: "#efefef",
              borderTopStyle: "solid",
              borderTopWidth: 1,
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }
          : null,
      ]}
    >
      <LinkButton
        to={to}
        testID={testID}
        style={width >= 480 ? { width: 228 } : null}
      >
        {children}
      </LinkButton>
    </View>
  )
}

const styles = StyleSheet.create({
  cardAction: {
    paddingTop: Spacing.large,
    paddingRight: Spacing.large,
    paddingBottom: Spacing.largest,
    paddingLeft: Spacing.large,
  },
})
