import React from "react"
import { Platform, TouchableOpacity, Text, View } from "react-native"
import { useLinkProps } from "@react-navigation/native"

import { Buttons } from "../styles"

export default function LinkButton({ to, action, children, style, ...rest }) {
  const { onPress, ...props } = useLinkProps({ to, action })

  const [isHovered, setIsHovered] = React.useState(false)

  if (Platform.OS === "web") {
    // It's important to use a `View` or `Text` on web instead of `TouchableX`
    // Otherwise React Native for Web omits the `onClick` prop that's passed
    // You'll also need to pass `onPress` as `onClick` to the `View`
    // You can add hover effects using `onMouseEnter` and `onMouseLeave`
    return (
      <View
        onClick={onPress}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        style={[
          style,
          Buttons.base,
          { transitionDuration: "150ms", opacity: isHovered ? 0.5 : 1 },
        ]}
        {...props}
        {...rest}
      >
        <Text style={Buttons.text}>{children}</Text>
      </View>
    )
  }

  return (
    <TouchableOpacity
      style={Buttons.base}
      onPress={onPress}
      {...props}
      {...rest}
    >
      <Text style={Buttons.text}>{children}</Text>
    </TouchableOpacity>
  )
}
