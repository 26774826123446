import React, { useEffect, useState } from "react"
import { useWindowDimensions, StyleSheet, View } from "react-native"
import { useNavigation } from "@react-navigation/native"
import axios from "axios"

import captureException from "../utils/captureException"

import AppScreen from "../components/AppScreen"
import ScrollView from "../components/ScrollView"
import SecondaryButton from "../components/SecondaryButton"
import SecondaryHeading from "../components/SecondaryHeading"
import SmallText from "../components/SmallText"
import TopNav from "../components/TopNav"

import { Colors, Spacing } from "../styles"

const defaultLocation = {
  coords: {
    latitude: 28.538336,
    longitude: -81.379234,
  },
}

export default function LeaguesScreen() {
  const [location, setLocation] = useState(defaultLocation)
  const [leagues, setLeagues] = useState(null)
  const navigation = useNavigation()
  const window = useWindowDimensions()
  const width = window.width > 720 ? 720 : window.width - Spacing.larger * 2

  useEffect(() => {
    if (location) {
      const {
        coords: { latitude, longitude },
      } = location

      const getLeagues = async () => {
        try {
          const { data } = await axios.get(
            `/leagues?lat=${latitude}&lng=${longitude}`
          )
          if (data && data.status == "ok" && data.leagues) {
            setLeagues(data.leagues)
          } else {
            setLeagues([])
          }
        } catch (e) {
          captureException(e)
          setLeagues([])
        }
      }

      getLeagues()
    }
  }, [location])

  function renderLeagues() {
    if (leagues && leagues.length > 0) {
      return leagues.map((league) => renderLeague(league))
    } else {
      return (
        <View style={{ alignItems: "center", paddingTop: 32 }}>
          <SmallText>No Leagues Nearby</SmallText>
        </View>
      )
    }
  }

  function renderLeague(league) {
    const { id, name, course } = league

    return (
      <View key={id} style={styles.league}>
        <View style={[styles.leagueInfo, { width: width - 148 }]}>
          <SecondaryHeading>{name}</SecondaryHeading>
          <SmallText>
            {course.distance}mi - {course.name} - {course.city}, {course.state}
          </SmallText>
        </View>
        <View style={styles.leagueAction}>
          <SecondaryButton
            onPress={() => navigation.navigate("LeagueDetails", { id })}
          >
            View
          </SecondaryButton>
        </View>
      </View>
    )
  }

  return (
    <AppScreen>
      <TopNav menu={true} />
      <ScrollView>
        <View style={styles.main}>{renderLeagues()}</View>
      </ScrollView>
    </AppScreen>
  )
}

const styles = StyleSheet.create({
  main: {
    marginTop: Spacing.largest,
    backgroundColor: Colors.white,
    padding: Spacing.base,
  },
  league: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "stretch",
    paddingVertical: Spacing.base,
  },
  leagueInfo: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  leagueAction: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    width: 80,
  },
})
