import React from "react"
import { StyleSheet, Text, View } from "react-native"
import { Colors, Spacing, Typography } from "../styles"

export default function Error({ children }) {
  return (
    <View style={styles.errorMessage}>
      <Text style={styles.errorText}>{children}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  errorMessage: {
    backgroundColor: Colors.errorBackground,
    borderRadius: Spacing.tiny,
    padding: Spacing.larger,
    marginBottom: Spacing.largest,
  },
  errorText: {
    color: Colors.errorText,
    fontFamily: Typography.descriptionFontFamily,
    fontSize: Typography.baseFontSize,
  },
})
