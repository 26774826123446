import React from "react"
import InlineLink from "./InlineLink"
import { Typography } from "../styles"

export default function SmallInlineLink(props) {
  const { children, style, ...rest } = props
  return (
    <InlineLink style={[Typography.smallBodyText, style]} {...rest}>
      {children}
    </InlineLink>
  )
}
