import * as Colors from "./colors"
import * as Spacing from "./spacing"
import * as Typography from "./typography"

export const base = {
  alignItems: "center",
  marginVertical: Spacing.smallest,
  padding: Spacing.small,
  backgroundColor: Colors.sparkOrange,
  borderRadius: Spacing.tiny,
}

export const text = {
  color: Colors.white,
  backgroundColor: Colors.sparkOrange,
  fontSize: Typography.smallFontSize,
  fontFamily: Typography.headerFontFamily,
  textTransform: "uppercase",
  letterSpacing: 1,
}

export const secondary = {
  ...base,
  backgroundColor: Colors.white,
  borderRadius: Spacing.tiny,
  borderWidth: Spacing.hairline,
  borderStyle: "solid",
  borderColor: Colors.black,
}

export const secondaryText = {
  ...text,
  color: Colors.black,
  backgroundColor: Colors.white,
}
