import * as Colors from "./colors"

export const extraLargeFontSize = 24
export const largeFontSize = 20
export const baseFontSize = 16
export const smallFontSize = 14
export const smallestFontSize = 12

export const headerFontSize = extraLargeFontSize
export const buttonFontSize = smallFontSize

export const baseFontFamily = "Lato_300Light"
export const descriptionFontFamily = "Lato_400Regular"
export const boldFontFamily = "Lato_700Bold"
export const lightHeaderFontFamily = "TitilliumWeb_300Light"
export const headerFontFamily = "TitilliumWeb_700Bold"

export const link = {
  color: Colors.sparkOrange,
  fontWeight: "bold",
}

export const bodyText = {
  color: Colors.baseText,
  fontSize: baseFontSize,
  fontFamily: baseFontFamily,
}

export const smallBodyText = {
  ...bodyText,
  fontSize: smallFontSize,
}

export const headerText = {
  color: Colors.baseText,
  fontSize: headerFontSize,
  fontFamily: headerFontFamily,
  textTransform: "uppercase",
}

export const descriptionText = {
  color: Colors.baseText,
  fontSize: smallFontSize,
  fontFamily: descriptionFontFamily,
}
