import React from "react"
import { Image, StyleSheet, View } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { Asset } from "expo-asset"
import { Feather } from "@expo/vector-icons"

import { Colors, Spacing } from "../styles"

const logo = Asset.fromModule(require("../../assets/logo.png"))

export default function TopNav({ menu }) {
  const navigation = useNavigation()

  return (
    <View style={styles.container}>
      <Image style={{ width: 160, height: 40 }} source={logo} />
      {menu ? (
        <Feather
          name="menu"
          size={32}
          color="black"
          onPress={() => navigation.toggleDrawer()}
        />
      ) : (
        <View styles={styles.empty} />
      )}
    </View>
  )
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    padding: Spacing.larger,
    backgroundColor: Colors.white,
    height: 80,
  },
  empty: {
    backgroundColor: Colors.white,
  },
})
