export const hairline = 1
export const tiny = 2
export const smallest = 4
export const smaller = 8
export const small = 12
export const base = 16
export const large = 20
export const larger = 24
export const largest = 28
export const extraLarge = 40

export const sectionPadding = base
