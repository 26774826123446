import React from "react"
import { ActivityIndicator } from "react-native"
import { Colors, Spacing } from "../styles"

export default function Loading() {
  return (
    <ActivityIndicator
      style={{ marginTop: Spacing.extraLarge }}
      size="large"
      color={Colors.sparkOrange}
    />
  )
}
