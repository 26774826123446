import React from "react"

import Screen from "../components/Screen"
import Text from "../components/Text"
import TopNav from "../components/TopNav"

export default function HelpScreen() {
  return (
    <Screen>
      <TopNav menu={true} />
      <Text>Help!</Text>
    </Screen>
  )
}
