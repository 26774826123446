export const sparkOrange = "#fb4d2f"
export const black = "#000000"
export const lightGray = "#f8f8f8"
export const mediumGray = "#dddddd"
export const gray = "#999999"
export const white = "#ffffff"
export const lightRed = "#ffd2d2"
export const darkRed = "#d8000c"

export const baseText = black
export const background = lightGray
export const sectionBackground = white
export const errorBackground = lightRed
export const errorText = darkRed
