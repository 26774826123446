import React from "react"
import { Text } from "react-native"
import { Typography } from "../styles"

export default function CardSubtitle(props) {
  const { children, style, ...rest } = props
  return (
    <Text style={[Typography.descriptionText, style]} {...rest}>
      {children}
    </Text>
  )
}
