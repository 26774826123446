import React, { useCallback, useEffect, useState } from "react"
import {
  useWindowDimensions,
  RefreshControl,
  StyleSheet,
  View,
} from "react-native"
import { useNavigation } from "@react-navigation/native"
import axios from "axios"
import captureException from "../utils/captureException"

import AppScreen from "../components/AppScreen"
import AvatarPile from "../components/AvatarPile"
import CalendarDateIcon from "../components/CalendarDateIcon"
import Card from "../components/Card"
import CardAction from "../components/CardAction"
import CardHeader from "../components/CardHeader"
import CardSubtitle from "../components/CardSubtitle"
import CardTitle from "../components/CardTitle"
import Error from "../components/Error"
import Heading from "../components/Heading"
import ScrollView from "../components/ScrollView"
import Text from "../components/Text"
import TopNav from "../components/TopNav"

import { Spacing, Typography } from "../styles"

export default function LeagueHomeScreen() {
  const window = useWindowDimensions()
  const navigation = useNavigation()
  const width = window.width > 720 ? 720 : window.width - Spacing.base * 2

  const [data, setData] = useState(null)
  const [error, setError] = useState(null)
  const [refreshing, setRefreshing] = useState(false)

  let league, currentRound, team, user
  if (data) {
    ;({ league, currentRound, team, user } = data)
  }

  useEffect(() => {
    if (data && data.league) {
      navigation.setOptions({ title: `Spark Golf - ${data.league.name}` })
    }
  }, [data])

  const getData = async () => {
    try {
      const { data } = await axios.get("/league_membership")
      setData(data)
      setError(null)
    } catch (e) {
      captureException(e)
      setError("Server Error - Please Try Again Later")
    }
    setRefreshing(false)
  }

  useEffect(() => {
    getData()
  }, [])

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    getData()
  }, [])

  function renderMain() {
    const members = team && team.members ? team.members : [user]

    return (
      <>
        <View style={{ marginBottom: Spacing.largest }}>
          <Heading>{league.name}</Heading>
          <View style={styles.team}>
            <View style={styles.teamInfo}>
              <Text style={styles.teamName}>
                {team ? team.name : user.abbreviatedName}
              </Text>
            </View>
            <AvatarPile
              avatars={members.map((u) => {
                return {
                  id: u.id,
                  imageUrl: u.customPhotoUrl,
                  placeholder: u.initials,
                }
              })}
            />
          </View>
        </View>

        {currentRound && renderCurrentRound()}
      </>
    )
  }

  function renderCurrentRound() {
    return (
      <Card>
        <CardHeader>
          <CalendarDateIcon
            month={currentRound.monthAbbreviation}
            day={currentRound.dayOfMonth}
          />
          <View>
            <CardTitle>Round {currentRound.number}</CardTitle>
            <CardSubtitle>
              {currentRound.relativeDayName} at {currentRound.course.name}
            </CardSubtitle>
            <CardSubtitle>
              Tees off at {currentRound.startTimeOfDay} from the{" "}
              {currentRound.tee.name} tees
            </CardSubtitle>
          </View>
        </CardHeader>
        <CardAction width={width} to={`/rounds/${currentRound.id}`}>
          View Round
        </CardAction>
      </Card>
    )
  }

  return (
    <AppScreen>
      <TopNav menu={true} />
      <ScrollView
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        {error && <Error message={error} />}
        {data && renderMain()}
      </ScrollView>
    </AppScreen>
  )
}

const styles = StyleSheet.create({
  team: {
    marginTop: Spacing.base,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
  },
  teamInfo: {
    alignItems: "flex-start",
  },
  teamName: {
    fontFamily: Typography.lightHeaderFontFamily,
    textTransform: "uppercase",
  },
})
