import React from "react"
import { Image, StyleSheet, Text, View } from "react-native"
import { Colors, Typography } from "../styles"

export default function Avatar({ imageUrl, placeholder, tuck }) {
  if (imageUrl) {
    return (
      <Image
        source={{ uri: imageUrl }}
        style={[styles.image, tuck ? { marginRight: -12 } : null]}
      />
    )
  } else {
    return (
      <View style={[styles.circle, tuck ? { marginRight: -12 } : null]}>
        <Text style={styles.text}>{placeholder}</Text>
      </View>
    )
  }
}

const imageStyle = {
  borderWidth: 2,
  borderColor: Colors.white,
  borderRadius: 20,
  width: 44,
  height: 44,
  backgroundColor: Colors.black,
}
const styles = StyleSheet.create({
  image: imageStyle,
  circle: {
    ...imageStyle,
    borderStyle: "solid",
    justifyContent: "center",
    alignItems: "center",
  },
  text: {
    lineHeight: 36,
    fontSize: Typography.smallFontSize,
    fontFamily: Typography.boldFontFamily,
    color: Colors.white,
  },
})
