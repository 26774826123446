import React from "react"
import { StyleSheet, Text, View } from "react-native"

import { Colors, Spacing, Typography } from "../styles"

export default function CalendarDateIcon({ month, day }) {
  return (
    <View style={styles.icon}>
      <Text style={styles.month}>{month}</Text>
      <Text style={styles.day}>{day}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  icon: {
    borderWidth: Spacing.hairline,
    borderStyle: "solid",
    borderColor: Colors.black,
    width: 40,
    height: 40,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
    marginRight: Spacing.base,
  },
  month: {
    color: Colors.black,
    fontFamily: Typography.headerFontFamily,
    fontSize: 12,
    lineHeight: 16,
    textTransform: "uppercase",
  },
  day: {
    color: Colors.black,
    fontFamily: Typography.headerFontFamily,
    fontSize: 16,
    lineHeight: 20,
    textTransform: "uppercase",
  },
})
