import React from "react"
import { Platform, StyleSheet, TextInput } from "react-native"
import { Colors, Spacing } from "../styles"

const Component = React.forwardRef((props, ref) => {
  const { selectionColor, style, ...rest } = props
  return (
    <TextInput
      selectionColor={selectionColor || Colors.gray}
      style={[styles.textInput, style]}
      ref={ref}
      {...rest}
    />
  )
})
export default Component

const baseStyle = Platform.OS === "web" ? { outlineWidth: 0 } : {}

const styles = StyleSheet.create({
  textInput: {
    ...baseStyle,
    height: 40,
    paddingHorizontal: Spacing.small,
    borderWidth: Spacing.hairline,
    borderColor: Colors.gray,
    borderRadius: Spacing.tiny,
    marginBottom: Spacing.small,
  },
})
