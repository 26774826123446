import React, { useCallback, useEffect, useState } from "react"
import { Image, RefreshControl, StyleSheet, View } from "react-native"
import { useNavigation } from "@react-navigation/native"
import { Asset } from "expo-asset"
import axios from "axios"

import captureException from "../utils/captureException"

import AppScreen from "../components/AppScreen"
import AvatarPile from "../components/AvatarPile"
import Button from "../components/Button"
import Error from "../components/Error"
import Heading from "../components/Heading"
import Loading from "../components/Loading"
import ScrollView from "../components/ScrollView"
import Paragraph from "../components/Paragraph"
import SmallHeading from "../components/SmallHeading"
import SmallText from "../components/SmallText"
import Text from "../components/Text"

import { Colors, Spacing, Typography } from "../styles"
import SecondaryHeading from "../components/SecondaryHeading"
import { TouchableOpacity } from "react-native-gesture-handler"

const calendar = Asset.fromModule(require("../../assets/icons/calendar.png"))
const club = Asset.fromModule(require("../../assets/icons/club.png"))
const group = Asset.fromModule(require("../../assets/icons/group.png"))
const golf = Asset.fromModule(require("../../assets/icons/golf.png"))
const trophy = Asset.fromModule(require("../../assets/icons/trophy.png"))
const sparkBolt = Asset.fromModule(require("../../assets/icons/spark-bolt.png"))

const carotUp = Asset.fromModule(require("../../assets/icons/caret-up.png"))
const carotDown = Asset.fromModule(require("../../assets/icons/caret-down.png"))

export default function LeagueDetailsScreen({ route }) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [league, setLeague] = useState(null)
  const [refreshing, setRefreshing] = useState(false)
  const [calendarOpen, setCalendarOpen] = useState(false)
  const [scoringOpen, setScoringOpen] = useState(false)
  const [handicapsOpen, setHandicapsOpen] = useState(false)
  const [faqOpen, setFaqOpen] = useState(false)

  const navigation = useNavigation()

  const { id } = route.params

  const getLeague = async () => {
    try {
      const { data } = await axios.get(`/leagues/${id}`)
      if (data && data.status == "ok" && data.league) {
        setError(null)
        setLeague(data.league)
      } else if (data && data.status == "error" && data.error) {
        setError(data.error)
        setLeague(null)
      } else {
        setError("Server Error")
        setLeague(null)
      }
      setLoading(false)
      setRefreshing(false)
    } catch (e) {
      captureException(e)
      setError(e.message)
      setLeague(null)
      setLoading(false)
      setRefreshing(false)
    }
  }

  useEffect(() => {
    if (id) {
      getLeague()
    }
  }, [id])

  const onRefresh = useCallback(() => {
    setRefreshing(true)
    getLeague()
  }, [])

  if (error) {
    return (
      <AppScreen>
        <ScrollView contentContainerStyle={styles.container}>
          <Heading>Error</Heading>
          <Error>{error}</Error>
        </ScrollView>
      </AppScreen>
    )
  }

  if (!id || (league && !league.id)) {
    return <NoLeague />
  }

  if (loading) {
    return (
      <AppScreen>
        <ScrollView contentContainerStyle={styles.container}>
          <Loading />
        </ScrollView>
      </AppScreen>
    )
  }

  function IconSection({ icon, children }) {
    return (
      <View
        style={{
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "stretch",
          marginBottom: Spacing.base,
        }}
      >
        <Image
          style={{
            width: 16,
            height: 16,
            marginTop: 4,
            marginRight: Spacing.base,
          }}
          source={icon}
        />
        <Text
          style={{
            lineHeight: Spacing.larger,
            flexShrink: 1,
          }}
        >
          {children}
        </Text>
      </View>
    )
  }

  const {
    name,
    course,
    summary,
    memberCount,
    avatars,
    startShortDate,
    endShortDate,
    ended,
    futureRoundCount,
    rounds,
  } = league

  function renderLeaguePlayers() {
    if (!league || memberCount < 12) {
      return <View />
    }

    if (!avatars && avatars.length == 0) {
      return (
        <View style={styles.leaguePlayers}>
          <Text style={styles.leaguePlayersText}>{memberCount} members</Text>
        </View>
      )
    }

    const plusMemberCount = memberCount - avatars.length
    return (
      <View style={styles.leaguePlayers}>
        <AvatarPile avatars={avatars} />
        {plusMemberCount > 0 && (
          <Text style={styles.leaguePlayersText}>
            + {plusMemberCount} members
          </Text>
        )}
      </View>
    )
  }

  return (
    <AppScreen style={{ backgroundColor: Colors.white, padding: 0, margin: 0 }}>
      <ScrollView
        style={styles.scrollview}
        contentContainerStyle={styles.container}
        refreshControl={
          <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
        }
      >
        <Heading>{name}</Heading>
        <SmallText>
          {course.name}, {course.city}, {course.state}
        </SmallText>
        <SmallText>{summary}</SmallText>
        {renderLeaguePlayers()}

        {!ended && (
          <Button
            onPress={() =>
              navigation.navigate("LeagueRegistrationScreen", { id })
            }
          >
            Join For Free
          </Button>
        )}

        <SecondaryHeading style={{ marginTop: Spacing.largest }}>
          Overview
        </SecondaryHeading>
        <Paragraph>
          Ready to play Spark Golf? Spark is a 9-hole, recreational golf league
          where golfers have fun, connect with others and experience friendly
          competition.
        </Paragraph>
        <Paragraph>
          2-person teams play a net best-ball format earning points based on how
          they finish in each league round. Play more rounds, earn more points
          and have more fun all season!
        </Paragraph>
        <Paragraph>
          Joining the league is free! League rounds are available for purchase
          each week or save by grabbing a 9 or 18 round Spark Pass.
        </Paragraph>
        <IconSection icon={calendar}>
          Season runs {startShortDate}
          {endShortDate ? ` - ${endShortDate}` : ""}
        </IconSection>
        <IconSection icon={club}>
          {futureRoundCount} round{futureRoundCount != 1 ? "s" : ""} available,
          play as much or as little as you like
        </IconSection>
        <IconSection icon={group}>
          Register as a 2 person team or as a single
        </IconSection>
        <IconSection icon={golf}>
          Handicaps provided. No existing handicap necessary.
        </IconSection>
        <IconSection icon={trophy}>
          Single round & season-long winners
        </IconSection>
        <ExpandableSection
          title="League Schedule"
          open={calendarOpen}
          toggle={setCalendarOpen}
        >
          <View
            style={{
              backgroundColor: Colors.white,
              marginVertical: Spacing.base,
            }}
          >
            {rounds.map((round) => (
              <View
                key={round.id}
                style={{
                  flexDirection: "row",
                  justifyContent: "space-between",
                  backgroundColor: Colors.white,
                  borderBottomWidth: Spacing.hairline,
                  borderBottomColor: Colors.mediumGray,
                  paddingVertical: Spacing.base,
                }}
              >
                <Text>Round {round.number}</Text>
                <Text>{round.startShortDay}</Text>
                <Text>{round.startTimeOfDay}</Text>
              </View>
            ))}
          </View>
        </ExpandableSection>
        <ExpandableSection
          title="Scoring Format"
          open={scoringOpen}
          toggle={setScoringOpen}
        >
          <Paragraph style={{ marginTop: Spacing.base }}>
            Spark is a recreational golf league focused on having fun. Part of
            the fun is friendly competition. We aim to keep the competition fair
            and enjoyable for everyone, no matter their skill level.
          </Paragraph>
          <Paragraph>
            2-Person teams play a net best-ball format. Each player plays their
            own ball into the hole. This is NOT a scramble. After each ball is
            holed, the team enters each player’s score into the Spark app which
            calculates the net score based on current handicaps. The lowest net
            score for the hole between the 2 players is the score for that team
            on that hole.
          </Paragraph>
          <Paragraph>
            Net double bogey is the max score for each hole. If this is reached
            prior to the ball being holed, the player should pick up to increase
            pace of play.
          </Paragraph>
          <Paragraph>
            The team that has the best score in a round is the winner for that
            round. Team will receive points in the season long competition based
            on how they finish. The team that finishes in 1st place will receive
            the most points, 2nd will receive less than 1st, 3rd will receive
            less that 2nd, etc. The number of points each team gets varies based
            on the number of teams that play in the round. Check the leaderboard
            for the round to see how many points each team received.
          </Paragraph>
          <Paragraph>
            Skins will also be available each round. A skin is awarded to an
            individual who has the lowest non-tied net score on a hole during a
            specific league round.
          </Paragraph>
        </ExpandableSection>
        <ExpandableSection
          title="Spark Handicaps"
          open={handicapsOpen}
          toggle={setHandicapsOpen}
        >
          <Paragraph style={{ marginTop: Spacing.base }}>
            An existing handicap is not necessary to play Spark Golf. Spark
            provides all players a handicap when joining the league by asking
            for their playing ability. Spark then assigns a specific number of
            Spark strokes (handicap) based on this table:
          </Paragraph>
          <TwoColumnRow
            col1="Shoots around 36 for 9 holes"
            col2="0 Spark strokes"
          />
          <TwoColumnRow
            col1="Shoots around 40 for 9 holes"
            col2="3 Spark strokes"
          />
          <TwoColumnRow
            col1="Shoots around 45 for 9 holes"
            col2="6 Spark strokes"
          />
          <TwoColumnRow
            col1="Shoots around 50 for 9 holes"
            col2="9 Spark strokes"
          />
          <TwoColumnRow col1="Shoots 55+ for 9 holes" col2="12 Spark strokes" />
          <Paragraph style={{ marginTop: Spacing.base }}>
            A "stroke" provides a player an advantage on a specific hole by
            reducing their actual or "gross" score to calculate their "net"
            score on that hole.
          </Paragraph>
          <Paragraph>
            Example - If a player makes a gross 5 on a hole where they are
            receiving a stroke, their net score is 4.
          </Paragraph>
          <Paragraph>
            Each player's Spark strokes will be applied to their 9 hole round
            starting with the lowest handicap hole on the course’s scorecard.
          </Paragraph>
          <Paragraph>
            Example - If a player is receiving 3 Spark stokes, they will get a
            stroke on the 3 lowest handicap holes of the nine.
          </Paragraph>
          <Paragraph>
            The max number of Spark strokes for a round is 18. Players are
            awarded strokes on holes based on the lowest handicap holes. For
            example, a 12-stroke player receives 2 strokes on the 3 lowest
            handicap holes and 1 stroke on the remaining 6 holes.
          </Paragraph>
          <Paragraph>
            Once a player plays Spark Golf, the number of strokes they receive
            fluctuates based on league scores. Spark calculates this by taking
            65% of the average number of strokes over par for the player’s last
            10 rounds.
          </Paragraph>
        </ExpandableSection>
        <ExpandableSection
          title="Frequently Asked Questions"
          open={faqOpen}
          toggle={setFaqOpen}
        >
          <SmallHeading style={{ marginTop: Spacing.larger }}>
            Who plays spark golf?
          </SmallHeading>
          <Paragraph>
            Everyone! Spark is open to anyone looking to have a great time on
            the golf course. We have good players, we have bad players. We have
            players looking to meet new friends, we have players looking to hang
            with old friends. Come out and enjoy a relaxed and fun atmosphere
            created for everyone.
          </Paragraph>

          <SmallHeading style={{ marginTop: Spacing.larger }}>
            How do I join?
          </SmallHeading>
          <Paragraph>
            Joining the league is free! Find the league that works best for you
            and click the join button to create a Spark account.
          </Paragraph>

          <SmallHeading style={{ marginTop: Spacing.larger }}>
            How do I pay?
          </SmallHeading>
          <Paragraph>
            League rounds are available for purchase each week or SAVE by
            grabbing a 9 or 18 round Spark Pass. Round credits can be purchased
            in the Spark app or at on our website at spark.golf.
          </Paragraph>

          <SmallHeading style={{ marginTop: Spacing.larger }}>
            How do I register for a round?
          </SmallHeading>
          <Paragraph>
            Each round’s registration period starts 6 days prior to the
            scheduled round and ends the day before. During this time, any
            league player with a round credit can register. Players can also
            unregister without penalty during this period. Season Passholders
            have the advantage of registering for any league round at anytime
            and avoid the risk of being waitlisted.
          </Paragraph>
        </ExpandableSection>

        <SecondaryHeading style={{ marginTop: Spacing.extraLarge }}>
          Ready to play Spark Golf?
        </SecondaryHeading>
        <Paragraph>
          Spark brings people together to play golf. Our goal is to make Spark
          Golf more fun, social and organized than your everyday round of golf.
          We want you to play more golf. #playsparkgolf
        </Paragraph>
        <IconSection icon={sparkBolt}>Join the league for free</IconSection>
        <IconSection icon={sparkBolt}>
          Pay for individual rounds as you go or save with a Spark Pass
        </IconSection>
        <IconSection icon={sparkBolt}>Have fun!</IconSection>
        {!ended && (
          <Button
            onPress={() =>
              navigation.navigate("LeagueRegistrationScreen", { id })
            }
          >
            Join For Free
          </Button>
        )}
      </ScrollView>
    </AppScreen>
  )
}

function NoLeague() {
  return (
    <AppScreen>
      <ScrollView contentContainerStyle={styles.container}>
        <Heading>League Not Found</Heading>
        <Error>This league has been removed</Error>
      </ScrollView>
    </AppScreen>
  )
}

function ExpandableSection({ title, children, open, toggle }) {
  return (
    <View>
      <View
        style={{
          height: 64,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          borderStyle: "solid",
          borderBottomColor: Colors.mediumGray,
          borderBottomWidth: Spacing.hairline,
        }}
      >
        <Heading
          style={{
            fontSize: Typography.baseFontSize,
          }}
        >
          {title}
        </Heading>
        <TouchableOpacity onPress={() => toggle(!open)}>
          <Image
            style={{
              width: 18,
              height: 10,
              resizeMode: "stretch",
              marginBottom: 8,
            }}
            source={open ? carotUp : carotDown}
          />
        </TouchableOpacity>
      </View>
      {open && children}
    </View>
  )
}

function TwoColumnRow({ col1, col2 }) {
  return (
    <View
      style={{
        flexDirection: "row",
        justifyContent: "space-between",
        backgroundColor: Colors.white,
        borderBottomWidth: Spacing.hairline,
        borderBottomColor: Colors.mediumGray,
        paddingVertical: Spacing.base,
      }}
    >
      <Text>{col1}</Text>
      <Text>{col2}</Text>
    </View>
  )
}

const styles = StyleSheet.create({
  scrollview: {
    backgroundColor: Colors.white,
    padding: 0,
    margin: 0,
  },
  container: {
    backgroundColor: Colors.white,
    padding: 0,
    margin: 0,
  },
  leaguePlayers: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    height: 44,
    marginVertical: Spacing.base,
  },
  leaguePlayersText: {
    fontFamily: Typography.headerFontFamily,
    fontSize: Typography.smallestFontSize,
    textTransform: "uppercase",
    marginLeft: Spacing.base,
  },
})
