import React from "react"
import { Image } from "react-native"
import { NavigationContainer } from "@react-navigation/native"
import { createStackNavigator } from "@react-navigation/stack"
import { createDrawerNavigator } from "@react-navigation/drawer"
import { Asset } from "expo-asset"

import DrawerContent from "./DrawerContent"

import HelpScreen from "../screens/HelpScreen"
import LeagueDetailsScreen from "../screens/LeagueDetailsScreen"
import LeagueHomeScreen from "../screens/LeagueHomeScreen"
import LeaguesScreen from "../screens/LeaguesScreen"
import RoundDetailsScreen from "../screens/RoundDetailsScreen"
import SignInScreen from "../screens/SignInScreen"

const arrowLeft = Asset.fromModule(require("../../assets/arrow-left.png"))

const AuthStack = createStackNavigator()
function AuthStackScreen() {
  return (
    <AuthStack.Navigator screenOptions={{ headerShown: false }}>
      <AuthStack.Screen
        name="SignIn"
        component={SignInScreen}
        options={{ title: "Spark Golf - Sign In" }}
      />
    </AuthStack.Navigator>
  )
}

const LeagueStack = createStackNavigator()
function LeagueStackScreen() {
  return (
    <LeagueStack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerTitle: null,
        headerBackImage: () => (
          <Image
            style={{
              width: 20,
              height: 20,
              marginHorizontal: 20,
            }}
            source={arrowLeft}
          />
        ),
      }}
    >
      <LeagueStack.Screen
        name="LeagueHome"
        component={LeagueHomeScreen}
        options={{ headerShown: false }}
      />
      <LeagueStack.Screen name="RoundDetails" component={RoundDetailsScreen} />
    </LeagueStack.Navigator>
  )
}

const LeaguesStack = createStackNavigator()
function LeaguesStackScreen() {
  return (
    <LeaguesStack.Navigator
      screenOptions={{
        headerBackTitleVisible: false,
        headerTitle: null,
        headerBackImage: () => (
          <Image
            style={{
              width: 20,
              height: 20,
              marginHorizontal: 20,
            }}
            source={arrowLeft}
          />
        ),
      }}
    >
      <LeaguesStack.Screen
        name="Leagues"
        component={LeaguesScreen}
        options={{ headerShown: false }}
      />
      <LeaguesStack.Screen
        name="LeagueDetails"
        component={LeagueDetailsScreen}
      />
    </LeaguesStack.Navigator>
  )
}

const Drawer = createDrawerNavigator()
function DrawerScreen() {
  return (
    <Drawer.Navigator
      initialRouteName="LeagueStack"
      drawerPosition="right"
      drawerContent={(props) => <DrawerContent {...props} />}
    >
      <Drawer.Screen name="LeagueStack" component={LeagueStackScreen} />
      <Drawer.Screen name="LeaguesStack" component={LeaguesStackScreen} />
      <Drawer.Screen name="Help" component={HelpScreen} />
    </Drawer.Navigator>
  )
}

export default ({ loggedIn }) => {
  let linking

  if (loggedIn) {
    linking = {
      prefixes: ["https://app.spark.golf"],
      config: {
        screens: {
          LeagueStack: {
            screens: {
              LeagueHome: "",
              RoundDetails: "rounds/:id",
            },
          },
          LeaguesStack: {
            screens: {
              Leagues: "golf-leagues",
              LeagueDetails: "leagues/:id",
            },
          },
          Help: "help",
        },
      },
    }
  } else {
    linking = {
      prefixes: ["https://app.spark.golf"],
      config: {
        screens: {
          SignIn: "/sign_in",
        },
      },
    }
  }

  return (
    <NavigationContainer linking={linking}>
      {loggedIn ? <DrawerScreen /> : <AuthStackScreen />}
    </NavigationContainer>
  )
}
