import * as Sentry from "sentry-expo"

export default function captureException(e) {
  if (Sentry.Native) {
    Sentry.Native.captureException(e)
  } else {
    // TODO: Set up Sentry for web?
    console.log(e)
  }
}
